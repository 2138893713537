<template>
    <div class="home_list">
        <div class="warpper">
            <section class="tit_screen">
                <h1>{{ current?.name }}</h1>
                <div class="ri">
                    <el-input placeholder="关键词搜索" v-model="query.keyword" @keydown.enter="search" />
                    <span class="sort" :class="query.sort === 'desc' ? 'reverse' : ''" @click="sortChange"></span>
                </div>
            </section>
            <section class="list">
                <div class="li" v-for="item in tableData" :key="item.id" @click="onItem(item)">
                    <div class="le">
                        <div class="label_name">
                            <span class="label" :style="{ backgroundColor: item.cssClass }">
                                {{ item.consultTypeName }}
                            </span>
                            <h2 class="textOverflowOne">{{ item.title }}</h2>
                        </div>
                        <p class="textOverflowOne">
                            {{ item.des }}
                        </p>
                    </div>
                    <div class="date">{{ item.createTime }}</div>
                    <div class="download" v-if="item.annexList && item.annexList.length > 0"
                        @click.stop="actDownloadZip(item.id, item.title)">
                        <i class="iconfont icon-xiazai"></i>
                    </div>
                </div>
                <el-empty description="暂无数据" v-if="tableData.length === 0" />
            </section>
            <section class="page_box">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="query.pageSize"
                    v-model:current-page="query.pageNum" />
            </section>
        </div>
    </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { apiConsultGetPageList } from '@/request/api';
import withLoading from '@/utils/loading';
import useDownloadZip from '@/views/layout/home/hooks/useDownloadZip';

const $router = useRouter();
const $route = useRoute();

const mapData = new Map([
    ['/newintro', { name: '特别关注', plateId: 136 }],
    ['/industryinfo', { name: '行业资讯', plateId: 137 }],
    ['/valuablebook', { name: '市场宝典', plateId: 138 }],
])

// 当前对象
const current = computed(() => {
    return mapData.get($route.path);
})

const query = reactive({
    keyword: '',
    sort: 'desc',// 排序 desc:降序 asc：升序
    pageNum: 1,
    pageSize: 10
})
const total = ref(0);
const tableData = ref([]);

// 获取数据
async function getList() {
    const params = { ...query, plateId: current.value.plateId };
    const { data } = await withLoading(apiConsultGetPageList)(params);
    total.value = data.total || 0;
    tableData.value = data.list || [];
}

// 分页
watch(() => query.pageNum, getList);

// 搜索
function search() {
    query.pageNum = 1;
    getList();
}

// 排序改变
function sortChange() {
    query.sort = query.sort === 'desc' ? 'asc' : 'desc';
    getList();
}

// 详情
function onItem(row) {
    $router.push({ path: '/article/details/' + row.id, query: { plateId: current.value.plateId, ...query } })
}

// 打包下载
const { actDownloadZip } = useDownloadZip();

getList();
</script>

<style lang="scss" scoped>
.home_list {
    --list_top_bottom_padding: 32px;
    padding: var(--list_top_bottom_padding) 56px;
    min-height: calc(100vh - var(--home-header-height));

    .warpper {
        min-height: calc(100vh - var(--home-header-height) - var(--list_top_bottom_padding) - var(--list_top_bottom_padding));
        background-color: #fff;
        border-radius: 8px;

        .tit_screen {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px;

            h1 {
                font-size: 20px;
                color: #2E313B;
            }

            .ri {
                display: flex;
                align-items: center;

                .sort {
                    width: 24px;
                    height: 24px;
                    background: url(../../../../assets/img/sort.png) center no-repeat;
                    background-size: 100%;
                    margin-left: 24px;
                    cursor: pointer;
                    transition: transform .3s;

                    &.reverse {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .list {
            padding: 0 32px;

            .li {
                min-height: 72px;
                margin-bottom: 8px;
                cursor: pointer;
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding-right: 160px;
                border-radius: 2px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: #F1F5FA;
                }

                .le {
                    width: 80%;

                    .label_name {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .label {
                            line-height: 20px;
                            padding: 0 6px;
                            background-color: #ccc;
                            color: #fff;
                            margin-right: 8px;
                            white-space: nowrap;
                            font-size: 12px;
                            border-radius: 2px;
                        }

                        h2 {
                            font-size: 16px;
                            color: #2C2F39;
                        }
                    }

                    p {
                        font-size: 12px;
                        color: #919398;
                    }
                }

                .date {
                    color: #919398;
                    font-size: 14px;
                }

                .download {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    position: absolute;
                    right: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .iconfont {
                        color: var(--primary-color);
                        font-size: 18px;
                    }

                    &:hover {
                        background-color: #E4EEFB;
                    }
                }
            }
        }

        .page_box {
            padding: 32px 22px;
            text-align: left;
        }
    }
}
</style>